import { useState, useEffect } from 'react';
import './main-page.css';
import NavBar from '../navigation/navbar';
import { Outlet, Route, Routes, useLocation } from "react-router-dom";
import axios from 'axios';
import { getUserToken, getUserCompany, getUserLastLogin, isAuthorised } from '../_services/permission.service';
import { Layout, Image, Divider, Row, Col } from 'antd';
import st_logo from '../assets/icons/st_logo.png';
import background from '../assets/images/Background.png';
import videoClip from '../assets/images/VideoBackground.mp4';
import { UserProvider } from '../_providers/UserProvider';
import { useUser } from '../_contexts/UserContext';
import { PermissionProvider } from '../_providers/PermissionProvider';
import LinkComponent from '../_components/link';
import Dashboard from '../dashboard/dashboard';
import Maintenance from '../maintenance/maintenance';
import GuardedRoute from '../ProtectedRoutes';
import Feedback from '../feedback/feedback';
import { MODULE_ID } from '../_services/constant.service';
import { AdminConsole } from '../admin/admin-console';
import Documentation from '../documentation/documentation';
import FlightLogs from '../flight-logs/flight-logs';
import { ConstantService } from '../_services/constant.service';
import { startTimeout } from '../_services/user.service';
import AccessTimeRoundedIcon from '@mui/icons-material/AccessTimeRounded';

const { Header, Content, Footer } = Layout;

const lastLogin = getUserLastLogin();
if (lastLogin) {
  const timePassed = calculateElapsedTime(lastLogin);

  // Check if 12 hours have passed
  const expirationTime = 12 * 60;
  if (timePassed >= expirationTime) {
    // If 12 hours have passed, delete items from session storage
    localStorage.clear();
    sessionStorage.clear();
    window.location.reload();
  } else {
    // If less than 12 hours have passed, set a timer to delete items after the remaining time
    const remainingTimeInMinutes = expirationTime - timePassed;

    startTimeout(remainingTimeInMinutes);
  }
}

async function getFlightDisplay() {
  let flightDisplayData = await ConstantService.getFlightDisplay();

  const company = getUserCompany();
  if (company === 'skyports') {
    flightDisplayData.data = flightDisplayData.data.filter((item :any)=> item.project === 'SKYPORTS');
  }

  return flightDisplayData.data;
}

function setAxiosHeader() {
  axios.interceptors.request.use(
    async config => {
      if (!config.url?.includes('amazonaws')) {
        config.headers = {
          'Authorization': `Bearer ${getUserToken()}`,
        }
      }

      return config;
    },
    error => {
      Promise.reject(error);
    }
  );

  axios.interceptors.response.use(
    (res) => {
      res.headers = {
        'Authorization': `Bearer ${getUserToken()}`,
      }
      if (res.status === 201) {
        console.log('Posted Successfully');
      }
      return res;
    },
    (err) => {
      return Promise.reject(err);
    }
  );
}

function calculateElapsedTime(takeoffTime: string): number {
  const currentTime = new Date();
  const takeoffDateTime = new Date(takeoffTime);
  const elapsedTimeInMilliseconds = currentTime.getTime() - takeoffDateTime.getTime();
  const elapsedTimeInMinutes = Math.floor(elapsedTimeInMilliseconds / (1000 * 60)); // Elapsed time in minutes
  return elapsedTimeInMinutes;
}

const MainContent = () => {
  const [flightDisplay, setFlightDisplay] = useState<React.ReactNode | null>(null);

  const fetchData = async () => {
    try {
      const result = await getFlightDisplay();
      setFlightDisplay(result);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    // Function to fetch data and set state
    const fetchAndSetData = async () => {
      if(getUserToken()){
      await fetchData();}
    };

    // Initial fetch
    fetchAndSetData();

    // Set up an interval to fetch data every 4 seconds
    const intervalId = setInterval(fetchAndSetData, 4000);

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, [getUserToken()]); // Empty dependency array to run the effect only once

  // Ensure MainContent always returns a valid JSX element
  return (
    <>
      {getUserToken() ? (
        <div style={{ position: 'relative' }}>
          <video src={videoClip} autoPlay loop muted width={'100%'} style={{ zIndex: 0 }} />
          <h1 id="flightDisplay" >
            {flightDisplay !== null && Array.isArray(flightDisplay) && flightDisplay.length > 0 ? (
              <>
                <span className="outlined-header1" style={{ color:'white', fontSize: '3vw' }}>
                  <u>UAV In Operation</u>
                </span>
                {flightDisplay.map((flight: any, index: number) => (
                  <div key={index} style={{ display: 'flex', alignItems: 'center', fontSize: '1.7vw' }}>
                    {flight.venue !== 'LANDED' ? (
                      <>
                        <span className="gray-circle" style={{ position: 'absolute', zIndex: 1, marginTop:'0.175em' }}></span>
                        <span className="green-circle blinking" style={{ position: 'absolute', zIndex: 2, marginTop:'0.175em' }}></span>{'  '}
                        <span className="neon-glow" style={{ marginLeft: '1em' }}>
                          {flight.uavId} [ {flight.project}-{flight.venue} ]{' '}
                          <span className="icon-border" style={{ verticalAlign: 'middle', position: 'relative', zIndex: 2, marginTop:'-0.15em' }}>
                            <AccessTimeRoundedIcon sx={{ fontSize: '2vw', zIndex: 3 ,verticalAlign: 'top', marginTop:'-0.0925em', marginLeft:'-0.06em'}} />
                          </span>
                          {'   '}{calculateElapsedTime(flight.takeoffTime)} mins
                        </span>
                      </>
                    ) : (
                      <>
                        <span className="yellow-circle" style={{ position: 'absolute', zIndex: 2, marginTop: '0.175em' }}></span>{' '}
                        <span className="neon-glow" style={{ color: 'white', marginLeft: '1em' }}>
                              {flight.uavId} [ {flight.venue} ]{'  '}
                        </span>
                      </>
                    )}
                  </div>
                ))}
              </>
            ) : (
              <>
                <span className="outlined-header2" style={{ fontSize: '3vw' }}>
                  <u>UAV In Operation</u>
                </span>
                <div style={{ display: 'flex', alignItems: 'center', fontSize: '1.7vw'}}>
                <span className="gray-circle" style={{ position: 'absolute', marginTop:'0.175em' }}></span>{' '}
                  <span className="outlined-header2" style={{ marginLeft:'1em' }}>
                    No UAV in operation
                  </span>
                </div>
              </>
            )}
          </h1>
        </div>
      ) : (
        <Image style={{ width: '100%' }} src={background} preview={false} />
      )}
    </>
  );
};

const FooterLink = () => {
  return (
    getUserToken()? <LinkComponent id='st_logo' to='/' image={{ src: st_logo }}/>:
    <img className="logo" src={st_logo}/> 
  );
}



function MainPage() {
  setAxiosHeader();
  let location = useLocation();
  return (
    <Layout>
      <UserProvider>
        <PermissionProvider>
          <Header className='header'>
            <NavBar />
          </Header>
          <Content>
            {location.pathname === '/' ? <MainContent /> : <Outlet />}
            <Routes>
              <Route path='/flightLogs' element={<GuardedRoute element={<FlightLogs />} isRouteAccessible={isAuthorised(MODULE_ID.MODULE_LEVEL.FLIGHT_LOGS)} redirectRoute='/' />} />
              <Route path='/admin_console' element={<GuardedRoute element={<AdminConsole />} isRouteAccessible={isAuthorised(MODULE_ID.FUNCTION_LEVEL.ADMIN_CONSOLE)} redirectRoute='/' />}>
                <Route path='/admin_console/:id' element={<GuardedRoute element={<AdminConsole />} isRouteAccessible={isAuthorised(MODULE_ID.FUNCTION_LEVEL.ADMIN_CONSOLE)} redirectRoute='/' />} />
              </Route>
              <Route path='/documentation' element={<GuardedRoute element={<Documentation />} isRouteAccessible={isAuthorised(MODULE_ID.MODULE_LEVEL.DOCUMENTATION)} redirectRoute='/' />} />
              <Route path='/dashboard' element={<GuardedRoute element={<Dashboard />} isRouteAccessible={isAuthorised(MODULE_ID.MODULE_LEVEL.DASHBOARD)} redirectRoute='/' />} />
              <Route path='/maintenance' element={<GuardedRoute element={<Maintenance />} isRouteAccessible={isAuthorised(MODULE_ID.MODULE_LEVEL.MAINTENANCE)} redirectRoute='/' />} />
              {/*<Route path='/sqn' element={<GuardedRoute element={<SQN />} isRouteAccessible={isAuthorised(MODULE_ID.MODULE_LEVEL.SQN)} redirectRoute='/' />} /> */}
              <Route path='/feedback' element={<GuardedRoute element={<Feedback />} isRouteAccessible={isAuthorised(MODULE_ID.FUNCTION_LEVEL.FEEDBACK_FORM)} redirectRoute='/' />} />
            </Routes>
          </Content>
            <Row>
              <Col style={{ marginLeft: '1em'}}>
              <FooterLink />
              </Col>
            </Row>
            <Row>
              <Col style={{ marginLeft: '1em', marginBottom: '1.05em'}}>
                Copyright © 2023 ST Engineering
              </Col>
            </Row>
        </PermissionProvider>
      </UserProvider>
    </Layout>
  );
}

export default MainPage;
