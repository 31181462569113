import "./feedback.css";
import { IFeedbackForm, IFeedbackOptions } from "../_models/feedback";
import { useState, useEffect, useRef } from "react";
import JiraService from "../_services/jira.service";
import { Button, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import type { UploadFile } from 'antd/es/upload/interface';
import Toastbox from '../_components/toastbox';
import { TOASTBOX_VARIATION, TOASTBOX } from '../_models/toastbox';

const baseFeedbackFormObj: IFeedbackForm = {
  name: "",
  email: "",
  category: "",
  project: "",
  subject: "",
  message: "",
  response: "",
};

const Feedback = () => {
  const [formData, setFormData] = useState(baseFeedbackFormObj);
  const [displayTicketIDField, setDisplayTicketIDField] = useState(false);
  const [feedbackOptions, setFeedbackOptions] = useState<IFeedbackOptions |null>(null);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const userName = sessionStorage.getItem("name") || "";
  const userEmail = sessionStorage.getItem("email") || "";
  const userProject = sessionStorage.getItem("custom:project")?.toUpperCase() || "UAS";
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  let [toastState, setToastState] = useState<TOASTBOX>({ openCloseState: false, message: '', type: 'success', duration: 10000, callback: () => false });

  const handleFileChange = ({ fileList }: { fileList: UploadFile[] }) => {
    const firstFile = fileList.length > 0 ? (fileList[0].originFileObj as File) : undefined;
    setFormData({ ...formData, file: firstFile });
    setFileList(fileList);
  };


  useEffect(() => {
    const retrieveDataOnInit = async () => {
      try {
        const constantsData = await JiraService.getFeedbackOptions();
        setFormData({
          name: userName || "",
          email: userEmail || "",
          category: "",
          project: userProject || "",
          subject: "",
          message: "",
          response: "No",
        });
        setFeedbackOptions(constantsData);
      } catch (error) {
        console.error('error: ', error)
      }
    }
    retrieveDataOnInit();
  }, [userName, userEmail, userProject]);

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    if (name === "category") {
      if (value === "Follow-up") {
        setDisplayTicketIDField(true);
      } else {
        setDisplayTicketIDField(false);
        setFormData({
          ...formData,
          [name]: value,
          ticket: "",
        });
        return;
      }
    }
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const onSubmit = async (event: any) => {
    event.preventDefault();

    const payload = await JiraService.createFeedbackPayload(formData);
    const res = await JiraService.addFeedback(payload);
    if (res.data.error) {
      displayToastMessage(res.data.error);
      return;
    }

    if(formData.file && res.data.id){
      const fileData = new FormData();
      fileData.append('file', formData.file);
      const fileRes = await JiraService.addAttachment(res.data.id, formData.file)
      displayToastMessage(fileRes.data.result);
    } else {
      displayToastMessage(res.data.id? 'Success': 'Failed');
    }
    setDisplayTicketIDField(false);
    setFormData({
      name: userName || "",
      email: userEmail || "",
      category: "",
      project: userProject || "",
      subject: "",
      message: "",
      response: "No",
    });
    setFileList([]);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const handleToastChanges = (state: boolean) => {
    setToastState({ ...toastState, openCloseState: state })
  };

  const displayToastMessage = (message: string) => {
    let msg = '';
    let type: TOASTBOX_VARIATION = 'success';
    switch(message){
      case 'Success':
        msg = 'Your feedback has been successfully received. Thank you!';
        type = 'success';
        break;
      case 'An error occurred':
        msg = 'An error occurred while submitting your feedback. Please try again.';
        type = 'error';
        break;
      case 'DNFF Ticket ID for follow up cannot be found':
        msg = 'Unable to locate the follow-up ticket ID. Please try again.';
        type = 'warning';
        break;
      case 'Attachment error occurred':
        msg = 'Feedback received, but the attachment upload encountered an issue.';
        type = 'info';
        break;
      default:
        msg = 'An error occurred while submitting your feedback. Please try again.';
        type = 'error';
        break;
    }
    const toastResult: TOASTBOX = { ...toastState, openCloseState: true, message: msg, type: type };
    setToastState({ ...toastState, ...toastResult });
  };

  return (
    <div className="background">
      <section className="content">
        <form className="feedback-form" onSubmit={onSubmit}>
          <h2>Share Your Feedback</h2>
          <div className="container">
            <div className="input-box">
              <label>Category</label>
              <select
                style={{ color: formData.category ? "#333" : "#777" }}
                name="category"
                placeholder="Enter your category"
                className="field"
                value={formData.category || ""}
                onChange={handleChange}
                required
              >
                <option key="Placeholder" value="" hidden>
                  Enter your category
                </option>
                {feedbackOptions?.category && feedbackOptions.category.map((option) => (
                  <option key={option}value={option}>{option}</option>
                ))}
              </select>
            </div>
            {displayTicketIDField && (
              <div className="input-box">
                <label>Follow-up Ticket ID</label>
                <input
                  type="number"
                  className="field"
                  placeholder="Enter your follow-up ticket id"
                  name="ticket"
                  min="0"
                  value={formData.ticket || ""}
                  onKeyDown={(evt) =>
                    ["e", "E", "+", "-"].includes(evt.key) &&
                    evt.preventDefault()
                  }
                  onChange={handleChange}
                  required
                />
              </div>
            )}
          </div>
          <div className="input-box">
            <label>Subject</label>
            <input
              type="text"
              className="field"
              placeholder="Enter your subject"
              name="subject"
              value={formData.subject || ""}
              onChange={handleChange}
              required
            />
          </div>
          <div className="input-box">
            <label>Content</label>
            <textarea
              name="message"
              className="field message"
              placeholder="Enter your message"
              value={formData.message || ""}
              onChange={handleChange}
              required
            ></textarea>
          </div>

          <div style={{ display: "grid", gridTemplateColumns: "auto 1fr", gap: "10px", marginTop: "20px" }}>
            <label className="label-alignment">Would you like a response?</label>
            <select
              style={{paddingLeft: formData.response === 'Yes'? '72.5px': '75px'}}
              name="response"
              className="responseField"
              value={formData.response || ""}
              onChange={handleChange}
              required
            >
              <option key="Placeholder" value="No" hidden>
                No
              </option>
              {feedbackOptions?.response &&
                feedbackOptions.response.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
            </select>

            <label className="label-alignment">Upload file attachment: </label>
            <div>
              <Upload
                fileList={fileList}
                maxCount={1}
                style={{ visibility: 'hidden', display: 'none'}}
                beforeUpload={() => false} // Prevent automatic upload
                onChange={handleFileChange}
              >
                <Button className="attachment-button" icon={<UploadOutlined />}>Select File</Button>
              </Upload>
            </div>
          </div>

 
          <button className="submit-button" type="submit">Submit Feedback</button>
        </form>
      </section>
      {toastState.openCloseState ? <Toastbox openCloseState={true} message={toastState.message} type={toastState.type} duration={toastState.duration} callback={handleToastChanges} /> : <></>}
    </div>
  );
};

export default Feedback;
