import axios from "axios";
import { environment } from "../environment";
import { JIRA_CUSTOMFIELDS, JIRA_CUSTOMFIELDS_DEV } from './constant.service';
import { IFlightLog } from '../_models/flightLog';
import { format } from 'date-fns';
import { UtilService } from "./utils.service";
import { DropdownSelectKeyValue } from "../_models/dropdown";
import { IFeedbackForm, IFeedbackOptions } from "../_models/feedback";
namespace JiraService {
    const apiUrl = environment.apiurl;
    const apiEndpoint = '/jira';
    const feedbackArray = ['category', 'response'];

    type REMOVE_OBJ_PAYLOAD = { [key: string]: [{ [key: string]: any }] };
    // Start of JIRA Api calls
    export function addOperatorLog(operatorLog: LOGS_PAYLOAD) {
        return axios.post(`${apiUrl}${apiEndpoint}/createOperatorLog`, { ...operatorLog });
    }

    export function getOperatorLog(startDate: string, endDate: string, project: string[]) {
        return axios.get<IFlightLog[]>(`${apiUrl}${apiEndpoint}/getOperatorLogs`, { params: { startDate, endDate, project } });
    }

    export function getAssets(assetTypes: string = '', company: string) {
        return axios.get(`${apiUrl}${apiEndpoint}/getAssets`, { params: { assetTypes, company } });
    }

    export function getUsersByGroup(groupName: string) {
        return axios.get(`${apiUrl}${apiEndpoint}/getUsersByGroup`, { params: { groupName } });
    }

    export function updateOperatorLog(id: string, updateObj: LOGS_PAYLOAD, removeObj: REMOVE_OBJ_PAYLOAD) {
        return axios.put(`${apiUrl}${apiEndpoint}/updateOperatorLog`, { id, updateObj, removeObj });
    }

    export function deleteOperatorLog(id: string[]) {
        return axios.post(`${apiUrl}${apiEndpoint}/deleteOperatorLog`, { id });
    }

    export function getUasProjects() {
        return axios.get(`${apiUrl}${apiEndpoint}/getUasProjects`);
    }

    export function getUasOperators() {
        return axios.get(`${apiUrl}${apiEndpoint}/getUasOperators`);
    }

    export function getUasFeedback(field: string){
        return axios.get(`${apiUrl}${apiEndpoint}/getUasFeedback`, { params: { field }});
    }

    export function addFeedback (payload: any) {
        return axios.post(`${apiUrl}${apiEndpoint}/createFeedbackIssue`, payload);
    }

    export function addAttachment (issueId: string, file: File){
        const formData = new FormData();
        formData.append('issueId', issueId);
        formData.append('file', file);
        return axios.post(`${apiUrl}${apiEndpoint}/addJiraAttachment`, formData)
    }

    // End of JIRA Api Calls
    // Start of JIRA helper fn
    const CF = 'customfield_';
    let OL: any;
    let FF: any;
    if (process.env.NODE_ENV === 'production') {
        OL = process.env.REACT_APP_ENV === 'development' ? JIRA_CUSTOMFIELDS_DEV.DNOL : JIRA_CUSTOMFIELDS.DNOL;
        FF = process.env.REACT_APP_ENV === 'development' ? JIRA_CUSTOMFIELDS_DEV.DNFF : JIRA_CUSTOMFIELDS.DNFF;
    } else {
        OL = JIRA_CUSTOMFIELDS_DEV.DNOL;
        FF = JIRA_CUSTOMFIELDS_DEV.DNFF;
    }
  
    const cfmsJiraMap: { [key: string]: { type: string, key: string } } = {
        project: { type: 'string', key: `${CF}${OL.PROJECT}` },
        operation: { type: 'string', key: `${CF}${OL.ACTIVITY}` },
        venue: { type: 'string', key: `${CF}${OL.VENUE}` },
        takeoffTime: { type: 'dateTime', key: `${CF}${OL.TAKE_OFF_TIME}` },
        groundTime: { type: 'dateTime', key: `${CF}${OL.LANDING_TIME}` },
        uavId: { type: 'value', key: `${CF}${OL.UAV_TAIL_NO}` },
        payloadId: { type: 'value', key: `${CF}${OL.PAYLOAD_ID}` },
        cargoWeight: { type: 'string', key: `${CF}${OL.CARGO_WEIGHT}`},
        armsId: { type: 'value', key: `${CF}${OL.ROTOR_ARMS_SN}` },
        battery1Id: { type: 'value', key: `${CF}${OL.BATTERY_SN}` },
        battery2Id: { type: 'array', key: `${CF}${OL.ADD_BATTERIES}` },
        pilotId: { type: 'string', key: `${CF}${OL.OPERATOR}` },
        externalPilotId: { type: 'string', key: `${CF}${OL.EXT_OPERATOR}` },
        aircraftType: { type: 'string', key: `${CF}${OL.AIRCRAFT_TYPE}` },
        droHubId: { type: 'value', key: `${CF}${OL.DROHUB_ID}`},
        droPortId: { type: 'value', key: `${CF}${OL.DROPORT_ID}`},
        fccVersion: { type: 'string', key: `${CF}${OL.FCC_VERSION}` },
        gcsVersion: { type: 'string', key: `${CF}${OL.GCS_VERSION}` },
        droPortVersion: { type: 'string', key: `${CF}${OL.DROPORT_VERSION}`},
        cfmsFlightId: { type: 'string', key: `${CF}${OL.CFMS_FLIGHT_ID}` },
        coverageArea: { type: 'string', key: `${CF}${OL.COVERAGE_AREA}` },
        flightDate: { type: 'date', key: `${CF}${OL.FLIGHT_DATE}` },
        flightTime: { type: 'duration', key: `${CF}${OL.FLIGHT_DURATION}` },
        // amendCount: { type: 'string', key: `${CF}${OL.AMEND_COUNT}` },
        remarks: { type: 'string', key: `description` },
        label: { type: 'string', key: `summary` }
    };

    const feedbackJiraMap: {[key:string]: {type:string, key:string}} = {
        name: {type: 'string', key: `${CF}${FF.NAME}`},
        email: {type: 'string', key: `${CF}${FF.EMAIL}`},
        category: {type: 'value', key: `${CF}${FF.CATEGORY}`},
        project: {type: 'value', key: `${CF}${FF.PROJECT}`},
        ticket: {type: 'string', key: `${CF}${FF.TICKET}`},
        subject: {type: 'string', key: `${CF}${FF.SUBJECT}`},
        message: {type: 'string', key: `${CF}${FF.MESSAGE}`},
        response: {type: 'value', key: `${CF}${FF.RESPONSE}`}
    }
    /**
     * 
     * @param flightLog - flight log data to translate into a jira payload.
     * @returns jira payload for operator log
     */
    type LOGS = { [key: string]: string | number | Date | string[] };
    type LOGS_PAYLOAD = { [key: string]: string | { [key: string]: string | number | { value: string[] } } | {}[] };
    export const createOperatorLogPayload = (flightLog: IFlightLog | LOGS, type: 'create' | 'edit') => {
        // Crafting the payload. Because JIRA doesn't accept null for certain property so can only submit data based on if data exist.
        let payload: LOGS_PAYLOAD = {};
        // Object.entries returns with a key/value mapping so looping through to determine whether does the key has a value to it and create the payload accordingly.
        for (const [key, value] of Object.entries(flightLog)) {
            const jiraType = cfmsJiraMap[key]?.type;
            const jiraKey = cfmsJiraMap[key]?.key;
            if (type === 'create' && (!value || !cfmsJiraMap[key] || value.length === 0)) {
                continue;
            }
            payload[jiraKey] = createJiraPayloadStructure(key, value, jiraType);
        }
        return payload;
    };

    export const createFeedbackPayload = (feedback: IFeedbackForm | LOGS) => {
        let feedbackPayload: LOGS_PAYLOAD = {}; 
        for (const [key, value] of Object.entries(feedback)) {
            if(key === 'file'){
                continue;
            }
            const jiraType = feedbackJiraMap[key]?.type;
            const jiraKey = feedbackJiraMap[key]?.key;
            const val = typeof value === 'string' ? value : value.toString();
            feedbackPayload[jiraKey] = jiraType === 'string'? val : {[jiraType] : val};
        }
        return feedbackPayload;
    }

    function createJiraPayloadStructure(key: string, value: string | number | string[], type: string) {
        const val = typeof value === 'string' ? value : value.toString();
        switch (type) {
            case 'dateTime': return format(new Date(val), `yyyy-MM-dd'T'HH:mm:ss.SSSxxxx`);
            case 'date': return format(new Date(val), `yyyy-MM-dd`);
            case 'string': return val;
            case 'duration': return UtilService.convertMSToTimeFormat(Number(val));
            case 'array': return additionalBatt(value as string[]);
            default: return { [cfmsJiraMap[key]?.type]: val };
        }
    }

    /**
     * 
     * @param obj battery obj from flight log data
     * @returns a array with the additional batteries
     */
    const additionalBatt = (obj: string[]) => {
        const batteries: { value: string }[] = [];
        obj.forEach((batt: string, i: number) => {
            batteries.push({ value: batt })
        });
        return batteries;
    };

    /**
     * To create a remove payload based on the object that consist of the asset type as the property and a nested object with the position of the asset and name of asset.
     * @param removeObj an object with the asset type as property and asset name and position of the selection as value
     * @returns the remove payload in this format - { [customField]: { remove: input data structure (e.g. {value})} }
     */
    type REMOVE_OBJ = { [key: string]: Array<string> };

    const getPayloadField = (payload: REMOVE_OBJ_PAYLOAD, field: string, value: string, errorMsg?: string) => {
        const newPayload = payload;
        if (newPayload[field]) errorMsg ? newPayload[field].push({ error: errorMsg }) : newPayload[field].push({ remove: { value } });
        else newPayload[field] = errorMsg ? [{ error: errorMsg }] : [{ remove: { value } }];
        return newPayload;
    };

    function getRemovePayload(removeObj: REMOVE_OBJ, updateObj: LOGS_PAYLOAD): REMOVE_OBJ_PAYLOAD {
        let payload: REMOVE_OBJ_PAYLOAD = {};
        Object.entries(removeObj).forEach(([key, value]) => {
            if (Array.isArray(value)) {
                value.forEach((val: string) => {
                    if (key === 'battery2Id') getPayloadField(payload, `${CF}${OL.ADD_BATTERIES}`, val);
                });
            }
        });
        return payload;
    }

    export function createRemovePayload(removeObj: REMOVE_OBJ, updateObj: LOGS_PAYLOAD, original: IFlightLog): REMOVE_OBJ_PAYLOAD {
        const removeKeys = Object.keys(removeObj);
        const updateKeys = Object.keys(updateObj);
        let removePayload: REMOVE_OBJ_PAYLOAD = {};
        if (removeKeys.length > 0) {
            removePayload = getRemovePayload(removeObj, updateObj);
        }
        updateKeys.forEach((key, i) => {
            if (Object.keys(removePayload).includes(key)) {
                delete removePayload[key];
            }
        });
        return removePayload;
    }

    export const createUpdateOperatorLogPayload = (oldData: IFlightLog, newData: IFlightLog) => {
        return Object.entries({ ...oldData, ...newData }).reduce((acc, [key, value]) => {
            if ((!Object.values(oldData).includes(value) || oldData[key as keyof IFlightLog] !== value)) {
                if (Array.isArray(value) && value.length > 0) {
                    acc[key] = value;
                } else {
                    if (!['flightId', 'key'].includes(key)) {
                        acc[key] = value;
                    }

                    if (['takeoffTime', 'flightDate', 'uavId'].includes(key) && !acc['label']) {
                        acc['label'] = oldData['label'];
                    }
                }
            }
            return acc;
        }, {} as LOGS);
    }

    /**
     * Load the required assets. 
     * @returns A list of assets based on what is retrieved in the Promise.all call. 
     */
    export async function loadAssets(userCompany: string) {
        // To retrieve constants data such as assets from JIRA and set to constants obj and pass it down to the relevant component
        // Using named fn because useEffect doesn't allow async ops.
        const result = await Promise.all([
            JiraService.getAssets('payload', userCompany),
            JiraService.getAssets('fuselage', userCompany),
            JiraService.getAssets('battery', userCompany),
            JiraService.getAssets('motor arms', userCompany),
            JiraService.getAssets('drohub', userCompany),
            JiraService.getAssets('droport', userCompany),
            JiraService.getUasProjects()])

        let tempConstants: any = {};

        if (result[0].status === 200 && result[0]?.data?.totalRetrieved !== 0) {
            const payload = result[0]?.data?.['payload']?.map((payload: { key: string, value: string }) => payload.value ?? [])?.sort() ?? [];
            tempConstants = { ...tempConstants, payload };
        }
        if (result[1].status === 200 && result[1]?.data?.totalRetrieved !== 0) {
            const fuselage = result[1]?.data?.['fuselage']?.map((fuselage: { key: string, value: string }) => fuselage.value ?? [])?.sort() ?? [];
            const aircraftTypeConst = result[1]?.data?.['aircraft type']?.sort((a: DropdownSelectKeyValue, b: DropdownSelectKeyValue) => UtilService.Sorter.defaultSort(a.value, b.value)) ?? [];
            tempConstants = { ...tempConstants, fuselage, 'aircraft type': aircraftTypeConst };
        }
        if (result[2].status === 200 && result[2]?.data?.totalRetrieved !== 0) {
            const battery = result[2]?.data?.['battery']?.map((battery: { key: string, value: string }) => battery.value ?? [])?.sort() ?? [];
            tempConstants = { ...tempConstants, battery };
        }
        if (result[3].status === 200 && result[3]?.data?.totalRetrieved !== 0) {
            const arms = result[3]?.data?.['motor arms'].map((motorArms: { key: string, value: string }) => motorArms.value ?? [])?.sort() ?? [];
            tempConstants = { ...tempConstants, 'motor arms': arms };
        }
        if (result[4].status === 200 && result[4]?.data?.totalRetrieved !== 0) {
            const drohub = result[4]?.data?.['drohub'].map((drohub: { key: string, value: string }) => drohub.value ?? [])?.sort() ?? [];
            tempConstants = { ...tempConstants, 'droHub': drohub };
        }
        if (result[5].status === 200 && result[5]?.data?.totalRetrieved !== 0) {
            const droport = result[5]?.data?.['droport'].map((droport: { key: string, value: string }) => droport.value ?? [])?.sort() ?? [];
            tempConstants = { ...tempConstants, 'droPort': droport };
        }
        if (result[6].status === 200 && result[6]?.data?.totalRetrieved !== 0) {
            let project = [];
            if (userCompany) {
                const listOfPoject: {[key: string]: string[]} = {
                    skyports: ['SKYPORTS']
                };
                project = listOfPoject[userCompany];
            } else {
                project = result[6] && result[6].data ? result[6].data.map((project: { key: string, value: string }) => project.value ?? [])?.sort() : [];
            }
            tempConstants = { ...tempConstants, project }
        }
        return tempConstants;
    }

    export function getJiraLogForPg(issueKey: string) {
        return axios.get(`${apiUrl}${apiEndpoint}/getJiraLogForPg`, { params: {key: issueKey} });
    }

    export async function getFeedbackOptions() {
        const apiArray = feedbackArray.map(feedbackField => {
            return JiraService.getUasFeedback(feedbackField.toUpperCase())
        });

        const result = await Promise.all(apiArray);

        let tempConstants: IFeedbackOptions = {};

        for (let i=0; i < feedbackArray.length; i++) {
            if(result[i].status === 200 && result[i].data.totalRetrieved !== 0) {
                const options = result[i].data.options;
                tempConstants = { ...tempConstants, [feedbackArray[i]]: options}
            }
        }
        return tempConstants;
    } 
}

export default JiraService;

// End of JIRA Helper Fn