import axios from "axios";
import { environment } from "../environment";
import { ListObjectV2 } from "../_models/documentation";

const apiUrl = environment.apiurl;

export async function getAll() {
    return axios.get<ListObjectV2>(`${apiUrl}/documentation/`);
}

// export async function download(key: {Key: string}) {
//     return axios.post(`${apiUrl}/documentation/download`, key);
// }

// export async function downloadFromUrl(url: string) {
//     return axios.get(url, {
//         headers: {
//             'Content-Type': 'application/octet-stream'
//         },
//         responseType: 'arraybuffer'
//     });
// } 

export async function download(key: {Key: string}) {
    return axios.post(`${apiUrl}/documentation/download`, key);
} 

export async function downloadDocumentationByProject(projectKey: string) {
    return axios.get(`${apiUrl}/documentation/${projectKey}`);
}

export async function upload(file: Blob, name: string, type: string) {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('fileName', name);
    formData.append('projectType', type.toLowerCase());
    return axios.post(`${apiUrl}/documentation/upload`, formData);
}

export async function deleteObject(key: {Key: string}) {
    return axios.post(`${apiUrl}/documentation/delete`, key);
} 
  
